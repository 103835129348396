import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpContext} from '@angular/common/http';
import {ApiConfig, ApiConfigService} from '@matchsource/api-config';
import {FeatureToggleSerializerService} from './feature-toggle-serializer.service';
import {map} from 'rxjs/operators';
import {FeatureToggleOption, FeatureToggleOptionDTO, FeatureToggleResult} from './feature-toggle.declarations';
import {FeatureToggleSettingsModel} from './feature-toggle.model';
import {FeatureToggleSettingsOptions} from '@matchsource/feature-toggle';
import flow from 'lodash-es/flow';
import {silentError, useSpinner} from '@matchsource/core';

@Injectable({
  providedIn: 'root',
})
export class FeatureToggleApiService {
  constructor(
    private readonly http: HttpClient,
    @Inject(ApiConfigService) private readonly config: ApiConfig,
    private readonly serializer: FeatureToggleSerializerService
  ) {}

  getSettings(options: FeatureToggleSettingsOptions = {}): Observable<FeatureToggleSettingsModel> {
    const context: HttpContext = flow(silentError(options.skipError || false), useSpinner(options.silent || false))();

    return this.http
      .get<FeatureToggleOptionDTO[]>(`${this.config.configsServiceUrl}toggles`, {context})
      .pipe(map(data => data.map(input => this.serializer.fromDTO(input))));
  }

  toggle(feature: string, enabled: boolean): Observable<FeatureToggleResult> {
    return this.http.put<FeatureToggleResult>(
      `${this.config.configsServiceUrl}toggles/${feature}?enabled=${enabled}`,
      null
    );
  }

  updateDetails(feature: FeatureToggleOption): Observable<void> {
    return this.http.put<void>(
      `${this.config.configsServiceUrl}toggles/${feature.code}/details`,
      this.serializer.toDTO(feature)
    );
  }
}
