import {Injectable} from '@angular/core';
import {Serializer} from '@matchsource/api-utils';
import {FeatureToggleOption, FeatureToggleOptionDTO} from './feature-toggle.declarations';

@Injectable({
  providedIn: 'root',
})
export class FeatureToggleSerializerService implements Serializer<FeatureToggleOption, FeatureToggleOptionDTO> {
  fromDTO(input: FeatureToggleOptionDTO): FeatureToggleOption {
    return {
      code: input.feature,
      description: input.desc,
      selected: input.enabled,
      readyToUse: input.readyToUse,
      note: input.detailedDesc,
    };
  }

  toDTO(model: FeatureToggleOption): FeatureToggleOptionDTO {
    return {
      feature: model.code,
      desc: model.description,
      enabled: model.selected,
      readyToUse: model.readyToUse,
      detailedDesc: model.note,
    };
  }
}
